import * as React from 'react';
import './Logo.css';
import { Cookies, useCookies } from 'react-cookie';

const cookies = new Cookies();

function LogoBear(props) {
    const [cookie, setCookie] = useCookies(['']);

    const bearScript = () => {
        const script = document.createElement('script');
        script.src = './logoAnimation.js';
        script.async = true;
        document.body.appendChild(script);

        console.log('loaded');
    };

    React.useEffect(() => {
        bearScript();
        const bearCookie = cookies.get('bearCookie');
        console.log(bearCookie);
        if (bearCookie && !bearCookie.loaded) {
            setCookie('bearCookie', { loaded: true }, { path: '/' });
            window.location.reload();
        } else {
            setCookie('bearCookie', { loaded: false }, { path: '/' });
        }
    }, [cookies]);

    return (
        <svg viewBox="0 0 194.05 120.82" className="z-50" {...props}>
            <g transform="translate(58.03 -57.75)">
                <rect
                    className="ground"
                    ry={3.18}
                    y={172.21}
                    x={33.49}
                    height={6.36}
                    width={102.52}
                />
                <path
                    className="bear__jeans"
                    d="M79.38 136.78a39.24 46.3 0 00-38.63 38.36h12.92a25.95 30.62 0 01.3-2.26 25.95 30.62 0 01.64-2.97 25.95 30.62 0 01.89-2.87 25.95 30.62 0 011.12-2.76 25.95 30.62 0 011.36-2.6 25.95 30.62 0 011.57-2.45 25.95 30.62 0 011.77-2.23 25.95 30.62 0 011.95-2.02 25.95 30.62 0 012.1-1.78 25.95 30.62 0 012.26-1.53 25.95 30.62 0 012.36-1.25 25.95 30.62 0 012.47-.96 25.95 30.62 0 012.53-.67 25.95 30.62 0 012.57-.37 25.95 30.62 0 011.84-.08 25.95 30.62 0 012.59.16 25.95 30.62 0 012.56.45 25.95 30.62 0 012.52.76 25.95 30.62 0 012.43 1.05 25.95 30.62 0 012.34 1.33 25.95 30.62 0 012.2 1.6 25.95 30.62 0 012.07 1.85 25.95 30.62 0 011.9 2.09 25.95 30.62 0 011.71 2.3 25.95 30.62 0 011.51 2.49 25.95 30.62 0 011.3 2.65 25.95 30.62 0 011.05 2.8 25.95 30.62 0 01.82 2.9 25.95 30.62 0 01.57 2.98 25.95 30.62 0 01.14 1.4h12.93a39.24 46.3 0 00-38.66-38.37z"
                />
                <path className="bear__belt" d="M57.51 125.62h43.72v18.89H57.51z" />
                <circle
                    className="bear__shoulder bear__shoulder--left"
                    r={11.34}
                    cy={114.82}
                    cx={49.51}
                />
                <rect
                    className="bear__tank"
                    ry={4.85}
                    y={106.5}
                    x={54.9}
                    height={33.43}
                    width={48.95}
                />
                <g className="bear__head">
                    <rect
                        className="bear__fur bear__face"
                        ry={5.64}
                        y={74.94}
                        x={61.23}
                        height={37.99}
                        width={36.29}
                    />
                    <circle
                        className="bear__fur bear__ear"
                        r={7.94}
                        cy={73.54}
                        cx={64.03}
                    />
                    <rect
                        className="bear__bandana"
                        ry={0}
                        y={82.08}
                        x={59.15}
                        height={9.83}
                        width={40.44}
                    />
                    <path
                        className="bear__muzzle"
                        d="M62.78 96.67c-.53 0-1.05.06-1.55.16v10.46a5.63 5.63 0 005.64 5.64h15.9v-6.65c0-5.32-3.76-9.6-8.43-9.6z"
                    />
                    <path
                        className="bear__bandana bear__bandana-tie"
                        d="M100.95 87.66h4.78c2.38 0 4.3 6.22 4.3 8.6h-9.08c-2.39 0-4.3-6.22-4.3-8.6h4.3z"
                    />
                    <rect
                        className="bear__nose"
                        ry={3.21}
                        y={95.63}
                        x={60.17}
                        height={6.43}
                        width={12.28}
                    />
                    <circle
                        className="bear__fur bear__ear"
                        cx={94.72}
                        cy={73.54}
                        r={7.94}
                    />
                </g>
                <g className="bear__keyboard-arm">
                    <g className="keyboard" transform="rotate(12.57 341.33 136.91)">
                        <path
                            className="keyboard__body"
                            d="M19.79 178.982h84.473v23.81H19.789z"
                        />
                        <g transform="translate(-10.878 -39.865) scale(1.0972)">
                            <rect
                                className="keyboard__key keyboard__key--accented"
                                width={29.2}
                                height={3.97}
                                x={44.35}
                                y={215.86}
                                ry={0}
                            />
                            <path
                                className="keyboard__key keyboard__key--accented"
                                d="M29.27 205.81h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key"
                                d="M64.46 205.81h3.97v3.97h-3.97zM59.43 205.81h3.97v3.97h-3.97zM54.4 205.81h3.97v3.97H54.4zM49.38 205.81h3.97v3.97h-3.97zM44.35 205.81h3.97v3.97h-3.97zM34.3 205.81h3.97v3.97H34.3zM39.32 205.81h3.97v3.97h-3.97zM64.46 210.83h3.97v3.97h-3.97zM59.43 210.83h3.97v3.97h-3.97zM54.4 210.83h3.97v3.97H54.4zM49.38 210.83h3.97v3.97h-3.97zM44.35 210.83h3.97v3.97h-3.97zM39.32 210.83h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key keyboard__key--accented"
                                d="M29.27 210.83h9v3.97h-9z"
                            />
                            <path
                                className="keyboard__key"
                                d="M74.51 205.81h3.97v3.97h-3.97zM69.49 210.83h3.97v3.97h-3.97zM74.51 210.83h3.97v3.97h-3.97zM39.32 215.86h3.97v3.97h-3.97zM34.3 215.86h3.97v3.97H34.3z"
                            />
                            <path
                                className="keyboard__key keyboard__key--accented"
                                d="M29.27 215.86h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key"
                                d="M69.49 205.81h3.97v3.97h-3.97zM89.59 210.83h3.97v3.97h-3.97zM79.54 210.83h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key keyboard__key--accented"
                                d="M84.57 210.83h3.97v3.97h-3.97zM89.59 215.86h3.97v3.97h-3.97zM84.57 215.86h3.97v3.97h-3.97zM79.54 215.86h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key"
                                d="M79.54 205.81h3.97v3.97h-3.97zM84.57 205.81h3.97v3.97h-3.97zM89.59 205.81h3.97v3.97h-3.97zM74.51 215.86h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key keyboard__key--accented"
                                d="M29.27 200.78h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key"
                                d="M64.46 200.78h3.97v3.97h-3.97zM59.43 200.78h3.97v3.97h-3.97zM54.4 200.78h3.97v3.97H54.4zM49.38 200.78h3.97v3.97h-3.97zM44.35 200.78h3.97v3.97h-3.97zM34.3 200.78h3.97v3.97H34.3zM39.32 200.78h3.97v3.97h-3.97zM74.51 200.78h3.97v3.97h-3.97zM69.49 200.78h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key keyboard__key--accented"
                                d="M79.54 200.78h3.97v3.97h-3.97zM84.57 200.78h3.97v3.97h-3.97zM89.59 200.78h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key"
                                d="M94.62 210.83h3.97v3.97h-3.97zM94.62 215.86h3.97v3.97h-3.97zM94.62 205.81h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key keyboard__key--accented"
                                d="M94.62 200.78h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key"
                                d="M99.65 210.83h3.97v3.97h-3.97zM99.65 215.86h3.97v3.97h-3.97zM99.65 205.81h3.97v3.97h-3.97z"
                            />
                            <path
                                className="keyboard__key keyboard__key--accented"
                                d="M99.65 200.78h3.97v3.97h-3.97z"
                            />
                        </g>
                        <rect
                            className="bear__paw bear__paw--right"
                            width={17.21}
                            height={9.35}
                            x={32.05}
                            y={196.97}
                            ry={4.68}
                        />
                    </g>
                    <g className="bear__arm">
                        <path
                            className="bear__fur"
                            d="M109.61 103.48a11.34 11.34 0 00-11.34 11.34 11.34 11.34 0 002.84 7.47v7.27H87.12a7.92 7.92 0 00-7.93 7.93c0 4.4 3.54 7.94 7.93 7.94h22.49a7.28 7.28 0 001.81-.23 7.92 7.92 0 005.17-4.1 11.47 11.47 0 001.53-5.8v-13a11.34 11.34 0 002.83-7.48 11.34 11.34 0 00-11.34-11.34z"
                        />
                        <rect
                            className="bear__fur bear__fur--light"
                            ry={1.98}
                            y={132.2}
                            x={85.99}
                            height={3.97}
                            width={20.03}
                        />
                        <circle
                            className="bear__fur bear__fur--light"
                            r={3.4}
                            cy={111.22}
                            cx={113.96}
                        />
                    </g>
                </g>
                <text
                    className="code-block"
                    id="original-code-block"
                    style={{
                        lineHeight: 1.25,
                        InkscapeFontSpecification: "'Impact, Condensed'",
                        fontVariantLigatures: 'normal',
                        fontVariantCaps: 'normal',
                        fontVariantNumeric: 'normal',
                        fontFeatureSettings: 'normal',
                        textAlign: 'start',
                    }}
                    x={-12.91}
                    y={107.23}
                    transform="scale(.87741 1.13971)"
                    fontWeight={400}
                    fontStretch="condensed"
                    fontSize={12.287}
                    fontFamily="Impact"
                    letterSpacing={0}
                    wordSpacing={0}
                    strokeWidth={0.307}
                >
                    <tspan
                        x={-12.91}
                        y={107.23}
                        style={{
                            InkscapeFontSpecification: "'Impact, Condensed'",
                            fontVariantLigatures: 'normal',
                            fontVariantCaps: 'normal',
                            fontVariantNumeric: 'normal',
                            fontFeatureSettings: 'normal',
                            textAlign: 'start',
                        }}
                    >
                        {'</>'}
                    </tspan>
                </text>
                <g className="codepen"></g>
            </g>
        </svg>
    );
}

export default LogoBear;